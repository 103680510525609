import { Link, useNavigate } from "@remix-run/react"
import {
  DollarSign,
  HelpCircle,
  Home,
  Loader2,
  LogOut,
  Mail,
  SettingsIcon,
  SunMoon,
  UserRoundPlus,
  Users,
} from "lucide-react"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarSeparator,
  useSidebar,
} from "~/components/ui/sidebar"
import useAccount from "~/hooks/use-account"
import useAppContext from "~/hooks/use-app-context"
import useTheme from "~/hooks/use-theme"
import { useLoginDialog } from "~/stores/dialog-store"
import { Badge } from "./ui/badge"
import { Card, CardContent } from "./ui/card"
import { buttonVariants } from "./ui/button"
import { cn } from "~/lib/utils"
import { useQuery } from "@tanstack/react-query"
import getDailyAvailableMessages from "~/queries/get-daily-user-message-count"
import getDailyAvailableImages from "~/queries/get-daily-user-image-count"

export function AppSidebar() {
  const { account } = useAccount()
  const navigate = useNavigate()
  const { openLogin } = useLoginDialog()
  const { toggleTheme } = useTheme()
  const { supabase } = useAppContext()
  const { setOpenMobile } = useSidebar()

  const menuItems = {
    main: [
      { name: "Explore", url: "/", icon: Home, enabled: true },
      {
        name: "Create Companion",
        icon: UserRoundPlus,
        enabled: true,
        onClick: () => {
          if (!account) {
            openLogin()
            return
          }
          navigate("/companion/create")
        },
      },
      {
        name: "My Companions",
        icon: Users,
        enabled: true,
        onClick: () => {
          if (!account) {
            openLogin()
            return
          }
          setOpenMobile(false)
          navigate("/companions")
        },
      },
      {
        name: "Messages",
        url: "/conversations",
        icon: Mail,
        enabled: true,
        onClick: () => {
          if (!account) {
            openLogin()
            return
          }
          navigate("/conversations")
        },
      },
      {
        name: "Premium",
        url: "/premium",
        icon: DollarSign,
        enabled: account && account.isPremium,
        onClick: () => {
          if (!account) {
            openLogin()
            return
          }
          navigate("/premium")
        },
      },
      {
        name: "Settings",
        url: "/settings/account",
        icon: SettingsIcon,
        enabled: !!account,
      },
    ],
    misc: [
      {
        name: "Faq",
        url: "/settings/account",
        icon: HelpCircle,
        enabled: false,
      },
      {
        name: "Theme",
        icon: SunMoon,
        enabled: true,
        onClick: () => {
          toggleTheme()
        },
      },
    ],
  }

  const logout = async () => {
    await supabase.auth.signOut()
    navigate("/")
  }

  return (
    <Sidebar collapsible="icon">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <div className="flex items-center gap-2">
                <div className="size-7 aspect-square flex items-center justify-center rounded-md bg-primary">
                  <span className="text-black font-extrabold text-xl brand">
                    F
                  </span>
                </div>
                <div className="brand text-2xl bg-gradient-to-r from-primary to-[#FECFB6] inline-block text-transparent bg-clip-text">
                  fanmade.ai
                </div>
              </div>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup className="">
          <SidebarGroupContent>
            <SidebarMenu>
              {menuItems.main.map((item, i) => (
                <SidebarMenuItem
                  key={i}
                  hidden={!item.enabled}
                  onClick={() => setOpenMobile(false)}
                >
                  {!item.onClick ? (
                    <SidebarMenuButton asChild>
                      <Link to={item.url!}>
                        <item.icon />
                        <span>{item.name}</span>
                      </Link>
                    </SidebarMenuButton>
                  ) : (
                    <SidebarMenuButton onClick={item.onClick}>
                      <item.icon />
                      <span>{item.name}</span>
                    </SidebarMenuButton>
                  )}
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarSeparator />
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              {menuItems.misc.map((item, i) => (
                <SidebarMenuItem
                  key={i}
                  hidden={!item.enabled}
                  onClick={() => setOpenMobile(false)}
                >
                  {!item.onClick ? (
                    <SidebarMenuButton asChild>
                      <Link to={item.url!}>
                        <item.icon />
                        <span>{item.name}</span>
                      </Link>
                    </SidebarMenuButton>
                  ) : (
                    <SidebarMenuButton onClick={item.onClick}>
                      <item.icon />
                      <span>{item.name}</span>
                    </SidebarMenuButton>
                  )}
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        {account && (
          <>
            <SidebarSeparator />
            <SidebarGroup>
              <SidebarGroupContent>
                <SidebarMenu>
                  <SidebarMenuItem>
                    <SidebarMenuButton onClick={logout}>
                      <LogOut />
                      <span>Logout</span>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
          </>
        )}
      </SidebarContent>
      {account && <Footer />}
    </Sidebar>
  )
}

function Footer() {
  const { open } = useSidebar()
  const { account } = useAccount()

  if (!open) {
    return
  }

  return (
    <SidebarFooter>
      <SidebarFooter>
        <Card className="shadow-none">
          <CardContent className="grid p-4">
            {account?.isPremium ? (
              <div className="flex flex-col gap-2">
                <Badge variant="secondary" className="w-fit">
                  Premium
                </Badge>
                <span className="text-sm text-muted-foreground">
                  Credits: {account?.credits}
                </span>
                <Link
                  to="/shop"
                  className={cn(buttonVariants({ size: "sm" }), "mt-2")}
                >
                  Buy more credits
                </Link>
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                <Badge variant="secondary" className="w-fit">
                  Free Tier
                </Badge>
                <AvailableDailyMessages />
                <Link
                  to="/premium"
                  className={cn(buttonVariants({ size: "sm" }), "mt-2")}
                >
                  Upgrade to Premium
                </Link>
              </div>
            )}
          </CardContent>
        </Card>
      </SidebarFooter>
    </SidebarFooter>
  )
}

function AvailableDailyMessages() {
  const { supabase } = useAppContext()
  const { account } = useAccount()

  const { data, isPending } = useQuery({
    queryKey: ["dailyMessages"],
    queryFn: async () => {
      const { count: msgCount } = await getDailyAvailableMessages(
        supabase,
        account!.user_id
      )

      const { count: imgCount } = await getDailyAvailableImages(
        supabase,
        account!.user_id
      )

      return {
        msgCount,
        imgCount,
      }
    },
  })

  if (isPending) {
    return (
      <div className="w-full flex justify-center h-10">
        <Loader2 className="animate-spin h-5 w-5" />
      </div>
    )
  }

  return (
    <>
      <span className="flex items-center text-xs text-muted-foreground">
        Free daily messages left: {data?.msgCount}
      </span>
      <span className="flex items-center text-xs text-muted-foreground">
        Free daily images left: {data?.imgCount}
      </span>
    </>
  )
}
