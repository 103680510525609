import { captureRemixErrorBoundaryError } from "@sentry/remix"
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react"
import type { LinksFunction } from "@remix-run/node"

import "./tailwind.css"
import { useEffect, useState } from "react"
import { createBrowserClient } from "@supabase/ssr"

import { AppProvider } from "./app-provider"
import { Toaster } from "./components/ui/toaster"
import { LoginDialog } from "./components/login-dialog"
import AppDialog from "./components/app-dialog"
import getUserAccount from "./queries/get-user-account"
import { SidebarProvider } from "~/components/ui/sidebar"
import { AppSidebar } from "~/components/app-sidebar"

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query"
import useAccount from "./hooks/use-account"
import AppDrawer from "./components/app-drawer"

const queryClient = new QueryClient()

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400..800;1,400..800&family=Righteous&display=swap",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200&icon_names=person_play",
  },
]

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en" className="light">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"
        />
        <meta name="apple-mobile-web-app-title" content="fanmade.ai" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#0c0a09" />
        <meta name="theme-color" content="#0c0a09" />
        <Meta />
        <link rel="manifest" href="/manifest.json" />
        <Links />
      </head>
      <body>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export async function loader() {
  return {
    env: {
      SUPABASE_URL: process.env.SUPABASE_URL!,
      SUPABASE_ANON_KEY: process.env.SUPABASE_ANON_KEY!,
    },
  }
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)
  return (
    <div className="w-full h-dvh flex justify-center items-center">
      <div className="flex flex-col gap-2">
        <p>
          An unexpected error occured. Please contact support@fanmade.ai if the
          error persists.
        </p>
        <Link className="underline" to="/">
          Back to home
        </Link>
      </div>
    </div>
  )
}

export default function App() {
  const loaderData = useLoaderData<typeof loader>()
  const { setAccount } = useAccount()

  const [supabase] = useState(() => {
    return createBrowserClient(
      loaderData.env.SUPABASE_URL,
      loaderData.env.SUPABASE_ANON_KEY
    )
  })

  const { data: account, isPending } = useQuery({
    queryKey: ["account"],
    queryFn: () => getUserAccount(supabase),
  })

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        if (!session?.user) {
          setAccount(null)
        }
      }
    )

    return () => {
      authListener?.subscription.unsubscribe()
    }
  }, [supabase, setAccount])

  if (isPending) {
    return (
      <div className="bg-background flex h-dvh w-full justify-center items-center">
        <div className="flex-col items-center justify-center">
          <h1 className="brand text-4xl text-primary animate-pulse">
            fanmade.ai
          </h1>
        </div>
      </div>
    )
  }

  return (
    <AppProvider supabase={supabase} account={account!}>
      <div className="bg-background">
        <SidebarProvider>
          <AppSidebar />
          <main className="mx-2 sm:mx-10 2xl:mx-auto relative w-full xl:max-w-7xl flex flex-col gap-8">
            <Outlet />
          </main>
        </SidebarProvider>
      </div>
      <AppDialog />
      <Toaster />
      <LoginDialog />
      <AppDrawer />
    </AppProvider>
  )
}
