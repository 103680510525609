import { SupabaseClient } from "@supabase/supabase-js"
import { MAX_DAILY_IMAGES } from "~/lib/constants"

interface GetDailyAvailableImagesResult {
  count: number
  error?: Error
}

const getDailyAvailableImages = async (
  supabase: SupabaseClient,
  userId: string
): Promise<GetDailyAvailableImagesResult> => {
  const dayStart = new Date()
  dayStart.setHours(0, 0, 0, 0)
  const dayEnd = new Date()
  dayEnd.setHours(23, 59, 59, 999)
  const { data, error } = await supabase
    .from("images")
    .select("id")
    .eq("user_id", userId)
    .eq("free_tier_generated", true)
    .gte("created_at", dayStart.toISOString())
    .lte("created_at", dayEnd.toISOString())

  if (error) {
    return {
      count: 0,
      error: new Error("Failed to get free tier images generated"),
    }
  }

  return {
    count: MAX_DAILY_IMAGES - data.length,
  }
}

export default getDailyAvailableImages
