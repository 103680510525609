import { SupabaseClient } from "@supabase/supabase-js"
import { Account } from "~/types"

const getUserAccount = async (
  supabase: SupabaseClient
): Promise<Account | null> => {
  const {
    data: { user },
  } = await supabase.auth.getUser()
  if (!user) {
    return null
  }

  const { data: account } = await supabase
    .from("accounts")
    .select("*")
    .eq("user_id", user.id)
    .single<Account>()
  if (!account) {
    return null
  }

  account.isPremium = new Date(account?.premium_ends_at || 0) > new Date()

  return account
}

export default getUserAccount
