import { SupabaseClient } from "@supabase/supabase-js"
import { MAX_DAILY_MESSAGES } from "~/lib/constants"

interface DailyAvailableMessagesResult {
  count: number
  error?: Error
}

const getDailyAvailableMessages = async (
  supabase: SupabaseClient,
  userId: string
): Promise<DailyAvailableMessagesResult> => {
  const dayStart = new Date()
  dayStart.setHours(0, 0, 0, 0)
  const dayEnd = new Date()
  dayEnd.setHours(23, 59, 59, 999)

  const { data, error } = await supabase
    .from("chat_messages")
    .select("created_at")
    .eq("user_id", userId)
    .eq("role", "user")
    .gte("created_at", dayStart.toISOString())
    .lte("created_at", dayEnd.toISOString())

  if (error) {
    return {
      count: 0,
      error: new Error("Failed to get daily available message count"),
    }
  }

  return {
    count: MAX_DAILY_MESSAGES - data.length,
  }
}

export default getDailyAvailableMessages
