import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from "~/components/ui/drawer"
import { useAppDrawer } from "~/stores/app-drawer-store"

const AppDrawer = () => {
  const { content, isOpen, setIsOpen, title, description, onAnimEnd } =
    useAppDrawer()
  return (
    <Drawer open={isOpen} onOpenChange={setIsOpen} onAnimationEnd={onAnimEnd}>
      <DrawerContent className="w-full md:max-w-md md:mx-auto px-4 pb-safe">
        <DrawerHeader className="px-0">
          <DrawerTitle>{title}</DrawerTitle>
          <DrawerDescription>{description}</DrawerDescription>
        </DrawerHeader>
        <div>{content}</div>
      </DrawerContent>
    </Drawer>
  )
}

export default AppDrawer
