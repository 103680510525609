import { Label } from "@radix-ui/react-label"
import { Button } from "~/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "~/components/ui/card"
import { Input } from "~/components/ui/input"
import { DialogContent, Dialog, DialogTitle } from "~/components/ui/dialog"
import { useLoginDialog } from "~/stores/dialog-store"
import { z } from "zod"
import { useState } from "react"
import { Coins, Loader2 } from "lucide-react"
import useAppContext from "~/hooks/use-app-context"
import { DiscordLogoIcon } from "@radix-ui/react-icons"

const loginSchema = z.object({
  email: z.string().email(),
})

export function LoginDialog() {
  const { isOpen, setOpen, isLogin, setIsLogin } = useLoginDialog()

  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        className="max-w-4xl p-0 border-none"
      >
        <DialogTitle hidden></DialogTitle>
        <LoginForm isLogin={isLogin} setIsLogin={setIsLogin} />
      </DialogContent>
    </Dialog>
  )
}

export function LoginForm({
  isLogin,
  setIsLogin,
}: {
  isLogin: boolean
  setIsLogin: (b: boolean) => void
}) {
  const { supabase } = useAppContext()
  const [emailError, setEmailError] = useState("")
  const [authError, setAuthError] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const login = async () => {
    setLoading(true)
    setEmailError("")
    setAuthError("")
    setEmailSent(false)

    const result = loginSchema.safeParse({ email })
    if (!result.success) {
      setLoading(false)
      setEmailError("Please enter a valid email address")
      return
    }
    const { data, error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: window.location.origin + "/auth/confirm",
      },
    })
    setLoading(false)
    if (error) {
      setAuthError(`An unexpected error occured: ${error.message}`)
      return
    }
    if (data) {
      setEmailSent(true)
    }
  }

  return (
    <Card className="overflow-hidden rounded-md border-none shadow-none">
      <div className="grid md:grid-cols-2 gap-6">
        <div className="hidden md:block relative">
          <div className="brand text-5xl bottom-4 flex justify-center items-center w-full text-primary font-extrabold absolute text-center">
            fanmade.ai
          </div>
          <img
            alt="Login"
            className="object-cover w-full h-full aspect-[3/4]"
            height="100%"
            src="https://imagedelivery.net/twDiE9yx-QmDio69NVVimA/af691b5d-a193-4623-ead7-cc743a759500/public"
            width="100%"
          />
        </div>
        <div className="p-6 space-y-6">
          <CardHeader className="p-0">
            <CardTitle className="text-3xl font-bold">
              {isLogin ? <span>Login</span> : <span>Sign up</span>}
            </CardTitle>
            {!isLogin && (
              <CardDescription>
                Sign up today and receive{" "}
                <span className="underline text-primary inline-flex gap-2 items-center">
                  20 free credits
                  <Coins className="h-5 w-5" />
                </span>
              </CardDescription>
            )}
          </CardHeader>

          {!emailSent && (
            <>
              <CardContent className="p-0 space-y-6">
                <div className="space-y-2">
                  <Label
                    htmlFor="email"
                    className={emailError && "text-destructive"}
                  >
                    Email
                  </Label>
                  <Input
                    id="email"
                    placeholder="Enter your email"
                    required
                    type="email"
                    className="h-10"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError && (
                    <div className="text-destructive text-sm">{emailError}</div>
                  )}
                  {authError && (
                    <div className="text-destructive text-sm">{authError}</div>
                  )}
                </div>
              </CardContent>
              <CardFooter className="flex flex-col p-0 space-y-4">
                <Button
                  disabled={loading}
                  className="w-full"
                  size="lg"
                  onClick={login}
                >
                  {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                  {isLogin ? <span>Login</span> : <span>Create account</span>}
                </Button>
                <div className="flex items-center space-x-2">
                  <hr className="flex-grow border-t" />
                  <span className="text-sm text-gray-500">
                    or continue with
                  </span>
                  <hr className="flex-grow border-t" />
                </div>
                <GoogleLoginButton />
                <DiscordLoginButton />
                {isLogin ? (
                  <div className="text-sm">
                    <Button variant="link" onClick={() => setIsLogin(false)}>
                      Don&apos;t have an account?
                    </Button>
                  </div>
                ) : (
                  <div>
                    <div className="text-sm">
                      <Button variant="link" onClick={() => setIsLogin(true)}>
                        Already have an account?
                      </Button>
                    </div>
                  </div>
                )}
              </CardFooter>
            </>
          )}
          {emailSent && (
            <div className="text-sm">
              A magic link has been sent to:{" "}
              <span className="underline font-semibold">{email}</span>. Clicking
              the link will directly log you in.
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}

const DiscordLoginButton = () => {
  return (
    <Button
      className="bg-violet-600 hover:bg-violet-600/95 text-white w-full"
      size="lg"
    >
      <DiscordLogoIcon className="icon-md mr-2" />
      Discord
    </Button>
  )
}

const GoogleLoginButton = () => {
  const [loading, setLoading] = useState(false)
  const { supabase } = useAppContext()

  const loginWithGoogle = async () => {
    setLoading(true)
    const url = new URL(window.location.href)
    const protocol = url.host.includes("localhost") ? "http" : "https"
    const redirectTo = `${protocol}://${url.host}/auth/callback`
    const { data } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo,
      },
    })

    if (data.url) {
      window.location.href = data.url
    }
  }

  return (
    <Button
      onClick={loginWithGoogle}
      disabled={loading}
      className="w-full"
      size="lg"
      variant="secondary"
    >
      {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
      Google
    </Button>
  )
}
